import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 6-6-6-6\\@80% 1RM`}</p>
    <p>{`Barbell Rows 6-6-6-6\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 30 S2OH (115/75)`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`V Ups`}</p>
    <p>{`Cash Out: 30 S2OH (115/75)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today we will be hosting a nutrition seminar starting at 11:15am.
 This is free for our members but you are welcome to invite friends.
 Non members will only pay \\$25.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Topics covered will include: `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Nutrient Timing`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Estimated Protein, Carbohydrate and Fat intake`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Meal Planning`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Eating for Performance & Recovery`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Positives and Negatives of Current Diet Trends`}</em></strong></p>
    <p><strong parentName="p">{`Get the most out of your hard work in the gym by optimizing your
nutrient intake!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      